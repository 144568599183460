import React, { useState } from 'react';
import {
  firestore,
  doc,
  setDoc,
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from '../firebase';
import { getCustomUid, sanitise } from '../utilities';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const MIN_PASSWORD_LENGTH = 6;
const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const noEmailText = 'Email required';
const emailInvalidText = 'Please enter valid email';
const noPasswordText = 'Password required';
const passwordTooShort = `Password must be ${MIN_PASSWORD_LENGTH} characters long`;
const noDisplayNameText = 'Display name required';

const emailValidation = (userInput, helperTextState, stateSetter) => {
  if (helperTextState === noEmailText) {
    if (userInput) {
      stateSetter(null);
    }
  }

  if (helperTextState === emailInvalidText) {
    if (emailRegex.test(userInput)) {
      stateSetter(null);
    }
  }
};

const passwordValidation = (userInput, helperTextState, stateSetter) => {
  if (helperTextState === noPasswordText) {
    if (userInput) {
      stateSetter(null);
    }
  }

  if (helperTextState === passwordTooShort) {
    if (userInput.length >= MIN_PASSWORD_LENGTH) {
      stateSetter(null);
    }
  }
};

const displayNameValidation = (userInput, helperTextState, stateSetter) => {
  if (helperTextState === noDisplayNameText) {
    if (userInput) {
      stateSetter(null);
    }
  }
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const EmailTextField = ({ emailHelperText, setEmailHelperText }) => (
  <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    label="Email Address"
    type="email"
    name="email"
    autoComplete="email"
    helperText={emailHelperText}
    error={Boolean(emailHelperText)}
    onChange={(e) =>
      emailValidation(e.target.value, emailHelperText, setEmailHelperText)
    }
    autoFocus
  />
);
const PasswordTextField = ({ passwordHelperText, setPasswordHelperText }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type={showPassword ? 'text' : 'password'}
      id="password"
      autoComplete="current-password"
      helperText={passwordHelperText}
      onChange={(e) =>
        passwordValidation(
          e.target.value,
          passwordHelperText,
          setPasswordHelperText,
        )
      }
      error={Boolean(passwordHelperText)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={
                showPassword ? 'hide the password' : 'display the password'
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const SubmitButton = ({ children }) => (
  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
    {children}
  </Button>
);

const FullWidthButton = ({ onClick, children }) => (
  <Button
    type="button"
    fullWidth
    variant="text"
    sx={{ mt: 3, mb: 2 }}
    onClick={onClick}
  >
    {children}
  </Button>
);

const SignIn = ({ toggleSignInState, setUser, setShowForgotPassword }) => {
  const [emailHelperText, setEmailHelperText] = useState(null);
  const [passwordHelperText, setPasswordHelperText] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (!email) {
      setEmailHelperText(noEmailText);
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailHelperText(emailInvalidText);
      return;
    }
    if (!password) {
      setPasswordHelperText(noPasswordText);
      return;
    }
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordHelperText(passwordTooShort);
      return;
    }

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUser(userCredential.user);
      })
      .catch((error) => {
        window.alert(
          `Error signing in. Please check your credentials and try again. \n\n${error.message.replace(
            'Firebase: ',
            '',
          )}`,
        );
        console.error(
          `🚨 Error signing in. Error code: ${error.code}. Error message: ${error.message}`,
        );
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <EmailTextField
            emailHelperText={emailHelperText}
            setEmailHelperText={setEmailHelperText}
          />
          <PasswordTextField
            passwordHelperText={passwordHelperText}
            setPasswordHelperText={setPasswordHelperText}
          />
          <Stack spacing={3}>
            <SubmitButton>Sign In</SubmitButton>
            <FullWidthButton onClick={toggleSignInState}>
              Don't have an account? Sign Up
            </FullWidthButton>
            <FullWidthButton onClick={() => setShowForgotPassword(true)}>
              Forgot password?
            </FullWidthButton>
          </Stack>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

const SignUp = ({ toggleSignInState, setUser }) => {
  const [emailHelperText, setEmailHelperText] = useState(null);
  const [passwordHelperText, setPasswordHelperText] = useState(null);
  const [displayNameHelperText, setDisplayNameHelperText] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const displayName = sanitise(data.get('display-name'));

    if (!email) {
      setEmailHelperText(noEmailText);
      return;
    }
    if (!emailRegex.test(email)) {
      setEmailHelperText(emailInvalidText);
      return;
    }
    if (!password) {
      setPasswordHelperText(noPasswordText);
      return;
    }
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordHelperText(passwordTooShort);
      return;
    }
    if (!displayName) {
      setDisplayNameHelperText(noDisplayNameText);
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const customUid = getCustomUid(userCredential.user, displayName);
        await setDoc(doc(firestore, 'users', customUid), {});

        updateProfile(auth.currentUser, {
          displayName,
        })
          .then(() => {
            setUser({ ...userCredential.user, displayName });
          })
          .catch((error) => {
            window.alert(
              `Error setting display name. \n\n${error.message.replace(
                'Firebase: ',
                '',
              )}`,
            );
            console.error(
              `🚨 Error setting display name. Error code: ${error.code}. Error message: ${error.message}`,
            );
          });
      })
      .catch((error) => {
        window.alert(
          `Error signing up.  \n\n${error.message.replace('Firebase: ', '')}`,
        );
        console.error(
          `🚨 Error signing up. Error code: ${error.code}. Error message: ${error.message}`,
        );
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <AccountCircle />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <EmailTextField
            emailHelperText={emailHelperText}
            setEmailHelperText={setEmailHelperText}
          />
          <PasswordTextField
            passwordHelperText={passwordHelperText}
            setPasswordHelperText={setPasswordHelperText}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="display-name"
            label="Display Name"
            type="display-name"
            id="display-name"
            autoComplete="display-name"
            helperText={displayNameHelperText}
            onChange={(e) =>
              displayNameValidation(
                e.target.value,
                displayNameHelperText,
                setDisplayNameHelperText,
              )
            }
            error={Boolean(displayNameHelperText)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Stack spacing={3}>
            <SubmitButton>Sign Up</SubmitButton>
            <FullWidthButton onClick={toggleSignInState}>
              Already have an account? Sign In
            </FullWidthButton>
          </Stack>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

const ForgotPassword = ({ setShowForgotPassword, setHasSignIn }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');

    if (!email) return;

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowForgotPassword(false);
      })
      .catch((error) => {
        throw new Error(
          `Error resetting password. Error code: ${error.code}. Error message: ${error.message}`,
        );
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <QuestionMark />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password reset
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Stack spacing={3}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send password reset email
            </Button>
            <Button
              type="button"
              fullWidth
              variant="text"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                setHasSignIn(true);
                setShowForgotPassword(false);
              }}
            >
              Back to sign In
            </Button>
          </Stack>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

const SignInOrSignUp = ({ setUser }) => {
  const [hasSignIn, setHasSignIn] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const toggleSignInState = () => setHasSignIn(!hasSignIn);

  if (showForgotPassword) {
    return (
      <ForgotPassword
        setShowForgotPassword={setShowForgotPassword}
        setHasSignIn={setHasSignIn}
      />
    );
  } else {
    return hasSignIn ? (
      <SignIn
        toggleSignInState={toggleSignInState}
        setUser={setUser}
        setShowForgotPassword={setShowForgotPassword}
      />
    ) : (
      <SignUp toggleSignInState={toggleSignInState} setUser={setUser} />
    );
  }
};

export default SignInOrSignUp;
