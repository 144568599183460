import { getInitialData } from '../utilities';

export const snowboardSkills = {
  steering: [
    {
      skill: 'Foot Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Front Leg Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Back Leg Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Upper Body Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'All Steering Elements',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  pressure: [
    {
      skill: 'Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Nollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Switch Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Switch Nollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Dolphin Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  edging: [
    {
      skill: 'Daffy',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: `'C' Carving`,
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Cross Under',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Toe-2-Toe',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Heel-2-Heel',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  rotary: [
    {
      skill: 'Tic-Tac',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: '90° of Separation',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: `180's Rotation`,
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Dolphin Turns',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: `180's Counter-Rotation`,
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  balance: [
    {
      skill: 'Stationary Toes',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Stationary Heels',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Toe Hops',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Heel Hops',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Moguls',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
};

export const initialSnowboardData = getInitialData(snowboardSkills);
