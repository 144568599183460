import React from 'react';
import { auth, signOut } from '../firebase';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const SignOut = ({ setUser }) => {
  const handleClick = (event) => {
    event.preventDefault();

    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(
          `Error happened when trying to sign out. Code: ${error.code}. Message: ${error.message}`,
        );
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleClick}
        >
          Sign Out
        </Button>
      </Box>
    </Container>
  );
};

export default SignOut;
